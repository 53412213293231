import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getMediaPriceByIds,
  updateMediaPrice,
} from "../../../actions/org/OrgMediaPriceActions";
import {
  resetSearchedSeller,
  searchSeller,
} from "../../../actions/seller/SellerActions";
import {
  clearSellerMediaByCity,
  getSellerMediaByCity,
} from "../../../actions/seller/SellerMediaActions";

// Utils & Constants
import {
  formatText,
  toLocaleString,
} from "../../../common-utils/string-utils/StringUtils";
import { getItem } from "../../../utils/localstorage";
import { LocalStorageKeys } from "../../../constants/GeneralConstants";

// Components
import {
  ImpressionsCell,
  LtsCell,
  MediaImage,
  MediaMetaData,
} from "../../../components/campaign-media-table-row/MediaRow";
import Spinner from "../../../components/spinner/Spinner";
import TableHeaders from "../../../components/table/TableHeaders";
import {
  ButtonWithLoader,
  CustomButton,
} from "../../../mavin/components/button/Button";
import PageHeader from "../../../mavin/components/page-header/PageHeader";

// Page Components
import OrgSettingsSidebar from "../OrgSettingsSidebar";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Campaign Name",
      className: "col-4",
    },
  },
  {
    title: {
      displayName: "Impression",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Lts",
      className: "col-1",
    },
  },
  {
    title: {
      displayName: "Seller price",
      className: "col-2",
    },
  },
  {
    title: {
      displayName: "Price setup",
      className: "col-3",
    },
  },
];

function SearchSellerInput({ sellerList, setSellerInfo }) {
  const dispatch = useDispatch();

  // only showing top 5 suggestions from sellerList
  const suggestedSellerList = sellerList.slice(0, 5);

  // Selector
  const sellerListLoading = useSelector(
    (state) => state.seller.sellerListLoading
  );

  function handleSearch(e) {
    dispatch(resetSearchedSeller());

    const searchText = e.target.value;
    if (searchText.length < 2) {
      return;
    }

    dispatch(searchSeller(searchText));
  }

  // Storing seller cities list
  function selectSeller(seller) {
    setSellerInfo(seller);
    dispatch(resetSearchedSeller());
  }

  return (
    <div className="row col-3">
      <div className="form-group mb-2 col px-0">
        <input
          type="search"
          className="form-control shadow-none rounded-lg"
          onChange={handleSearch}
          placeholder="Search Seller"
        />

        {/* Searched seller list */}
        {sellerList.length > 0 && (
          <div className="rounded-lg mt-1 dropdown-menu show col">
            {suggestedSellerList.map((seller) => (
              <option
                key={seller.id}
                className="dropdown-item cursor-pointer col"
                onClick={() => selectSeller(seller)}
              >
                {seller.name}
              </option>
            ))}
          </div>
        )}
      </div>
      <div className="col-2">{sellerListLoading && <Spinner />}</div>
    </div>
  );
}

function CitySelectionDropdown({ sellerInfo }) {
  // Dispatch
  const dispatch = useDispatch();

  // state
  const [cityName, setCityName] = useState("Select City");

  const { infoByCity = {}, id: sellerId } = sellerInfo;

  // Functions
  function onCitySelect(cityInfo) {
    const { cityId, cityName } = cityInfo;
    // clearing the previous city media list
    dispatch(clearSellerMediaByCity());

    setCityName(cityName);
    dispatch(getSellerMediaByCity(cityId, sellerId));
  }

  const infoByCityValues = Object.values(infoByCity);

  return (
    <div className="row col-2">
      <div className="dropdown">
        <button
          className={`border btn rounded-lg px-2 shadow-none`}
          data-toggle="dropdown"
          aria-expanded="false"
          disabled={infoByCityValues.length === 0}
        >
          {cityName}
        </button>

        <div className="dropdown-menu col rounded-lg">
          {infoByCityValues.map((cityInfo) => (
            <CustomButton
              key={cityInfo.cityId}
              buttonClassName="dropdown-item px-2"
              onClickFunction={() => onCitySelect(cityInfo)}
              displayContent={cityInfo.cityName}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function MediaInfoCell({ media }) {
  return (
    <td>
      <div className="d-flex align-items-center">
        <MediaImage media={media} />
        <div className="pl-2">
          <span>{media.title}</span>
          <MediaMetaData media={media} />
        </div>
      </div>
    </td>
  );
}

function PriceSetUpForm({ mediaPrice, mediaId }) {
  const dispatch = useDispatch();

  const { occupancyAvg, minOperatingPrice } = mediaPrice;

  // State
  const [occupancy, setOccupancy] = useState(occupancyAvg);
  const [mopPrice, setMopPrice] = useState(minOperatingPrice);

  // Selector
  const updateMediaPriceLoading = useSelector(
    (state) => state.orgMediaPrice.updateMediaPriceLoading[mediaId]
  );

  const inputConstant = [
    {
      id: "occupancy",
      label: "No. of Months",
      placeholder: "Months",
      value: occupancy || "",
      max: 12,
      title: "Number of months",
      setValueFunction: setOccupancy,
    },
    {
      id: "mopPrice",
      label: "Min.Op.Price",
      placeholder: "Price",
      value: mopPrice || "",
      max: Number.MAX_VALUE,
      title: "Minimum operating Price",
      setValueFunction: setMopPrice,
    },
  ];

  // org id
  const orgId = JSON.parse(getItem(LocalStorageKeys.USER)).orgId;

  const mediaPriceBean = {
    orgId,
    mediaId,
    occupancyAvg: occupancy,
    minOperatingPrice: mopPrice,
  };

  // Update Media Price
  function handleSubmit() {
    dispatch(updateMediaPrice(mediaPriceBean));
  }

  return (
    <div className="form-row ">
      {inputConstant.map((info) => (
        <div key={info.id} className="form-group col-4">
          <label htmlFor={info.id} title={info.title}>
            {info.label}
          </label>
          <input
            type="number"
            className="form-control shadow-none"
            id={info.id}
            min={0}
            max={info.max}
            value={info.value}
            onChange={({ target }) => info.setValueFunction(target.value)}
            placeholder={info.placeholder}
          />
        </div>
      ))}

      {/* Submit Button */}
      <div className="form-group col-4">
        <label className="w-100">&nbsp;</label>
        <ButtonWithLoader
          displayContent={"Submit"}
          onClickFunction={handleSubmit}
          isDisabled={!occupancy || !mopPrice || updateMediaPriceLoading}
          loader={updateMediaPriceLoading}
        />
      </div>
    </div>
  );
}

function PriceSetUpCell({ media }) {
  const { mediaId } = media;

  // Selector
  const mediaPrice =
    useSelector((state) => state.orgMediaPrice.mediaPriceInfo[mediaId]) || {};

  const mediaPriceLoading = useSelector(
    (state) => state.orgMediaPrice.mediaPriceInfoLoading
  );

  // checks for loading
  if (mediaPriceLoading) {
    return (
      <td className="text-center align-middle">
        <Spinner className="" />
      </td>
    );
  }
  // render price setup form once the media price api is completed
  return (
    <td className="align-middle">
      <PriceSetUpForm mediaPrice={mediaPrice} mediaId={mediaId} />
    </td>
  );
}

function SellerPriceCell({ media }) {
  const { pricing } = media;
  return (
    <td className="align-middle">
      <p>{formatText(toLocaleString(pricing.price))}</p>
    </td>
  );
}

function MediaListTableRow({ media }) {
  return (
    <tr>
      <MediaInfoCell media={media} />
      <ImpressionsCell impressionsElementStyle={"align-middle"} media={media} />
      <LtsCell ltsElementClass={"align-middle"} media={media} />
      <SellerPriceCell media={media} />
      <PriceSetUpCell media={media} />
    </tr>
  );
}

function MediaListTable({ mediaList }) {
  const dispatch = useDispatch();

  const mediaIds = mediaList.map((eachMedia) => eachMedia.mediaId);

  // to get media price for all media
  useEffect(() => {
    dispatch(getMediaPriceByIds(mediaIds));
  }, [dispatch]);

  return (
    <div className="table-responsive mt-3">
      <table className="table border">
        <TableHeaders
          scope={"col"}
          tableHeaders={tableHeaders}
          headerClass={"thead bg-alt"}
        />
        <tbody>
          {mediaList.map((media) => (
            <MediaListTableRow key={media.id} media={media} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

/**
 * Page
 */
function OrgMediaPricePage() {
  const dispatch = useDispatch();
  // State
  const [sellerInfo, setSellerInfo] = useState({});

  // Selector
  const sellerList = useSelector((state) => state.seller.sellerList);
  const mediaList = useSelector(
    (state) => state.sellerMedia.sellerMediaByCityList
  );
  const sellerMediaLoading = useSelector(
    (state) => state.sellerMedia.sellerMediaByCityLoading
  );

  const pageTitle = "Org :: Media Price";

  // Dispatch
  useEffect(() => {
    // clearing the seller media from state
    dispatch(clearSellerMediaByCity());
  }, [dispatch]);

  return (
    <>
      <OrgSettingsSidebar activeIdx={7} />

      <div className="content-wrapper">
        {/** Page Header */}
        <PageHeader title={pageTitle} shadow={true} />

        {/** Page Content */}
        <div className="page-content">
          {/* Search and city selector */}
          <div className="d-flex">
            <SearchSellerInput
              sellerList={sellerList}
              setSellerInfo={setSellerInfo}
            />
            <CitySelectionDropdown sellerInfo={sellerInfo} />
          </div>

          <hr className="row" />

          {/* Checks for page loading */}
          {sellerMediaLoading && <Spinner className="mt-2 spinner-center" />}

          {/* Media Table */}
          {mediaList.length > 0 && <MediaListTable mediaList={mediaList} />}
        </div>
      </div>
    </>
  );
}

export default OrgMediaPricePage;
