import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { selectExploreSite } from "../../../actions/map-view/media-sites/ExploreSitesSelectionActions";
import { closeConfirmCampaignMediaSelectionForm } from "../../actions/campaign/CampaignMediaSelectionActions";

// Constants and Utils
import { FormDataTargets } from "../../../constants/GeneralConstants";
import { toLocaleString } from "../../../common-utils/string-utils/StringUtils";
import { getSelectedMediaTotalPrice } from "../../../utils/MediaUtils";

// Components
import TableHeaders from "../../../components/table/TableHeaders";

// tableHeaders
const tableHeaders = [
  {
    title: {
      displayName: "Site Name",
      className: "col-6 align-middle",
    },
    subTitle: {
      className: "d-none",
    },
  },
  {
    title: {
      displayName: "Site Price",
      className: "col-3 align-middle text-center",
    },
    subTitle: {
      className: "d-none",
    },
  },
  {
    title: {
      displayName: "Actions",
      className: "col-3 align-middle text-center",
    },
    subTitle: {
      className: "d-none",
    },
  },
];

// Selected Media Sites Table
function SelectedMediaSitesTable({
  finalSelectedMediaSites = [],
  setFinalSelectedMediaSites = () => {},
}) {
  // handle Remove
  function handleRemove(mediaId) {
    setFinalSelectedMediaSites((prevState) =>
      prevState.filter((media) => media.mediaId !== mediaId)
    );
  }

  return (
    <div className="table-responsive overflow-auto max-h-400">
      <table className="table table-media">
        <TableHeaders tableHeaders={tableHeaders} headerClass={"thead"} />

        {/* rows */}
        <tbody>
          {finalSelectedMediaSites.map((media) => {
            const { title, price, mediaId } = media;
            return (
              <tr key={mediaId}>
                <td className="p-2 align-middle">
                  <span className="text-truncate-2" title={title}>
                    {title}
                  </span>
                </td>
                <td className="p-2 align-middle text-center">
                  <span>{price ? toLocaleString(Math.floor(price)) : 0}</span>
                </td>
                <td className="p-2 align-middle text-center">
                  <button
                    className="btn btn-outline-danger added-button shadow-none"
                    onClick={() => handleRemove(mediaId)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

// Budget Data Section
function BudgetDataSection({
  budget = 0,
  spend = 0,
  finalSelectedMediaSites = [],
}) {
  // total price of finalSelectedMediaSites
  const selectedMediaSitesPrice = getSelectedMediaTotalPrice(
    finalSelectedMediaSites
  );

  // data
  const totalBudget = budget ? toLocaleString(budget) : 0;
  const totalSpendBudget = spend + selectedMediaSitesPrice;
  const usedBudget = totalSpendBudget
    ? toLocaleString(Math.floor(totalSpendBudget))
    : 0;

  const remainingBudget = budget - totalSpendBudget;
  const remainingBudgetFormatted = remainingBudget
    ? toLocaleString(Math.ceil(remainingBudget))
    : 0;

  // className
  const remainingBudgetClassName =
    budget > totalSpendBudget ? "text-success" : "text-danger";

  return (
    <div>
      <h4>Budget Summary</h4>
      <div className="my-3">
        <p className="mb-1">Total Budget : {totalBudget}</p>
        <p className="mb-1">Used Budget : {usedBudget}</p>
        <p className="mb-1">
          Remaining Budget :{" "}
          <span className={remainingBudgetClassName}>
            {remainingBudgetFormatted}
          </span>
        </p>
      </div>
    </div>
  );
}

/**
 * Campaign Media COnfirm Selection Form
 */
function CampaignMediaConfirmSelectionForm({
  selectedMediaSites = [],
  budget = 0,
  spend = 0,
}) {
  const dispatch = useDispatch();
  const { campaignId } = useParams();

  // state
  const [finalSelectedMediaSites, setFinalSelectedMediaSites] = useState([]);

  // update state on mount
  useEffect(() => {
    setFinalSelectedMediaSites(selectedMediaSites);
  }, []);

  // handle submit
  function handleSubmitFrom() {
    dispatch(selectExploreSite(campaignId, finalSelectedMediaSites));

    // close
    dispatch(closeConfirmCampaignMediaSelectionForm());
  }

  return (
    <div
      className="modal fade"
      id={FormDataTargets.confirmCampaignMediaSelection}
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg mt-2" role="document">
        <div className="modal-content">
          {/* header */}
          <div className="bg-alt modal-header px-5">
            <h4 className="modal-title">Add To Campaign</h4>
          </div>

          {/* modal body */}
          <div className="modal-body px-5">
            {/* budget data */}
            <BudgetDataSection
              budget={budget}
              spend={spend}
              finalSelectedMediaSites={finalSelectedMediaSites}
            />

            {/* final Selected Media table */}
            <SelectedMediaSitesTable
              finalSelectedMediaSites={finalSelectedMediaSites}
              setFinalSelectedMediaSites={setFinalSelectedMediaSites}
            />
          </div>

          {/* Form Footer */}
          <div className="modal-footer border-0 px-5">
            <button
              type="button"
              className="btn btn-outline-primary rounded-lg shadow-none"
              data-dismiss="modal"
              onClick={() => dispatch(closeConfirmCampaignMediaSelectionForm())}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary rounded-lg shadow-none ml-2"
              data-dismiss="modal"
              onClick={handleSubmitFrom}
              disabled={finalSelectedMediaSites.length === 0}
            >
              Add To Campaign
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignMediaConfirmSelectionForm;
