import { toast } from "react-toastify";
import { all, put, takeLatest, delay } from "redux-saga/effects";

// Api
import {
  downloadExcelReportFn,
  downloadCampaignPdfReportFn,
  getCampaignSummaryData,
  initiateMavinReportGeneration,
  generateCampaignExcelReportFn,
} from "../../apis/CampaignReportAPI";

// Constants
import { CampaignReport } from "../../constants/action-constants/CampaignReportActionConstants";
import {
  CampaignReportStatus,
  ExcelReportStatus,
  FilenameExtensions,
  ReportConstants,
} from "../../constants/GeneralConstants";

// Utils
import { getErrorMessage } from "../../utils/util";
import { saveFile } from "../../common-utils/file-utils/FileUtils";
import { setErrorStatusCode } from "../../utils/ErrorUtils";

/**
 * Campaign overview
 * overview of a particular campaign
 * needs campaign id
 */
export function* getCampaignOverview(action) {
  try {
    const { id } = action.payload;
    const data = yield getCampaignSummaryData(id);

    // dispatching action
    yield put({
      type: CampaignReport.CAMPAIGN_OVERVIEW_SUCCESS,
      data: data,
    });
  } catch (err) {
    // stores the error and render the error image when the api fails
    setErrorStatusCode(err);

    /* istanbul ignore next */
    yield put({
      type: CampaignReport.CAMPAIGN_OVERVIEW_FAILURE,
      payload: err,
    });
  }
}

export function* generateReport(action) {
  try {
    const { campaignId } = action.payload;

    // Initiate the process for generating the report..
    yield initiateMavinReportGeneration(campaignId);

    // Fetch the summary to check if the report is generated
    let data = yield getCampaignSummaryData(campaignId);

    // We pool till the report is generated..
    while (data.summary.reportStatus !== CampaignReportStatus.GENERATED) {
      data = yield getCampaignSummaryData(campaignId);
      yield delay(5000);
    }

    yield put({
      type: CampaignReport.GENERATE_MAVIN_REPORT_SUCCESS,
    });

    // Once the report is generated we update the campaign summary details to reflect in the UI
    yield put({
      type: CampaignReport.CAMPAIGN_OVERVIEW_SUCCESS,
      data: data,
    });
  } catch (err) {
    yield put({
      type: CampaignReport.GENERATE_MAVIN_REPORT_FAILURE,
      payload: err,
    });
  }
}

export function* downloadExcelReport(action) {
  try {
    const { campaignId, campaignTitle, isMantaraySpecific } = action.payload;

    const data = yield downloadExcelReportFn(campaignId, isMantaraySpecific);

    // file name
    const excelFileName = campaignTitle
      ? `${campaignTitle}${FilenameExtensions.xlsx}`
      : ReportConstants.CAMPAIGN_XLS_REPORT;

    // save file
    saveFile(excelFileName, data);

    yield put({
      type: CampaignReport.DOWNLOAD_XLS_REPORT_SUCCESS,
      data: data,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: CampaignReport.DOWNLOAD_XLS_REPORT_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}

export function* downloadCampaignPdfReport(action) {
  try {
    const { campaignId, campaignTitle } = action.payload;

    const data = yield downloadCampaignPdfReportFn(campaignId);

    // file name
    const pdfFileName = campaignTitle
      ? `${campaignTitle}${FilenameExtensions.pdf}`
      : ReportConstants.CAMPAIGN_PDF_REPORT;

    // save file
    saveFile(pdfFileName, data);

    yield put({
      type: CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT_SUCCESS,
    });
  } catch (err) {
    const errMsg = getErrorMessage(err);
    yield put({
      type: CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT_FAILURE,
      payload: err,
    });
    toast.error(errMsg);
  }
}

function* getUpdatedCampaignSummaryForExcelReport(campaignId) {
  // Fetch the summary to check if the excel report is generated
  const data = yield getCampaignSummaryData(campaignId);

  const { campaign = {} } = data || {};
  const { excelReportStatus } = campaign || {};
  const isExcelReportGenerated =
    excelReportStatus === ExcelReportStatus.GENERATED;

  // if generated, return data
  if (isExcelReportGenerated) {
    toast.success("Excel report data is generated and can be downloaded.");
    return data;
  }

  // delay in api call every time : 30 seconds
  yield delay(30000);

  // recall the same function to generate excel report
  return yield getUpdatedCampaignSummaryForExcelReport(campaignId);
}

export function* generateCampaignExcelReport(action) {
  try {
    const { campaignId } = action.payload;

    // generate campaign excel report api
    yield generateCampaignExcelReportFn(campaignId);

    // calling campaign summary api, till the "excelReportStatus" = "GENERATED"
    const data = yield getUpdatedCampaignSummaryForExcelReport(campaignId);

    // generate excel report success
    yield put({
      type: CampaignReport.GENERATE_CAMPAIGN_EXCEL_REPORT_SUCCESS,
    });

    // campaign overview success
    yield put({
      type: CampaignReport.CAMPAIGN_OVERVIEW_SUCCESS,
      data: data,
    });
  } catch (err) {
    yield put({
      type: CampaignReport.GENERATE_CAMPAIGN_EXCEL_REPORT_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(CampaignReport.CAMPAIGN_OVERVIEW, getCampaignOverview),
    takeLatest(CampaignReport.GENERATE_MAVIN_REPORT, generateReport),
    takeLatest(CampaignReport.DOWNLOAD_XLS_REPORT, downloadExcelReport),
    takeLatest(
      CampaignReport.DOWNLOAD_CAMPAIGN_PDF_REPORT,
      downloadCampaignPdfReport
    ),
    takeLatest(
      CampaignReport.GENERATE_CAMPAIGN_EXCEL_REPORT,
      generateCampaignExcelReport
    ),
  ]);
}
