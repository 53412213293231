import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getBoundaries,
  getPincodeData,
  getWardData,
  setRadioBoxStatus,
  setRadioSelected,
} from "../../../actions/map-view/BoundariesActions";

// Constants and Utils
import { getMergedCityId } from "../CityInfoTempFixUtil";
import { Boundaries } from "../../../constants/action-constants/map-view/BoundariesActionConstants";

// Components
import NestedRadioList from "../../../components/nested-radiolist/NestedRadioList";
import Spinner from "../../../components/spinner/Spinner";

/**
 * Page Section
 */
function BoundariesListingSection({ dataList, cityId }) {
  const dispatch = useDispatch();

  // Selector State
  const dataListCityId = useSelector((state) => state.boundaries.cityId);
  const getBoundariesLoading = useSelector(
    (state) => state.boundaries.getBoundariesLoading
  );

  // LoadingMap for Each Boundary Type
  const boundariesLoadingMap = {
    Ward: useSelector((state) => state.boundaries.wardInfoLading),
    Pincode: useSelector((state) => state.boundaries.pincodeLoading),
  };

  // Dispatch
  useEffect(() => {
    if (dataList.length === 0 || dataListCityId !== cityId) {
      dispatch(getBoundaries(cityId));
    }
  }, [dispatch]);

  // Functions
  function executeBoundaryAction(id) {
    const labels = id.split("_");
    const parentLabel = labels[0];

    // TODO: For temporary fix of cityIds for Delhi and mumbai we are hardcoading the
    // their Ids and BBOX
    if (parentLabel === Boundaries.MAPPING.Ward) {
      dispatch(getWardData(getMergedCityId(cityId)));
      return;
    }

    // TODO: For temporary fix of cityIds for Delhi and mumbai we are hardcoading the
    // their Ids and BBOX
    else if (parentLabel === Boundaries.MAPPING.Pincode) {
      dispatch(getPincodeData(getMergedCityId(cityId)));
      return;
    }
  }

  function actionOnRadioBoxStatus(id, status) {
    dispatch(setRadioBoxStatus(id, status));
    executeBoundaryAction(id);
  }

  function actionOnRadioBoxSelect(label) {
    dispatch(setRadioSelected(label));
  }

  // Page Loading
  if (getBoundariesLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      {/* Boundaries Listing */}
      <NestedRadioList
        data={dataList}
        setSelected={actionOnRadioBoxSelect}
        setCheckboxStatus={actionOnRadioBoxStatus}
        boundariesLoadingMap={boundariesLoadingMap}
      />
    </>
  );
}

export default BoundariesListingSection;
